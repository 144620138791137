import AXIOS from "services/axios";

export const getStateList = async (id=50) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/dropdown_list/state/?country=${id}`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};
export const getIrelandStateList = async () => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/dropdown_list/state/?country=51`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};
export const getNationStateList = async () => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/dropdown_list/state_nation/`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};
export const getDistrictList = async (state) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/dropdown_list/district/?state=${state.id}`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};

export const getACList = async (state) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/dropdown_list/AC/?state=${state.id}`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};

export const getPCList = async (state) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/dropdown_list/PC/?state=${state.id}`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};

export const getPartyList = async (state) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/dropdown_list/political_party/`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};

export const getMappinList = async () => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/dropdown_list/initialload`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};
export const getCountryList =async()=>{
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/dropdown_list/nation/`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
}